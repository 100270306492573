import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../assets/DT-bluebackground.svg";
import animateScrollTo from "animated-scroll-to";

import LanguageSwitcher from "../Components/LanguageSwitcher";
import {Content} from "../Components/Layout";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export const headerHeight = 89;

export default function Header({locale}) {
  const [shadow, setShadow] = useState(window.pageYOffset !== 0);
  const [show, setShow] = useState(false);
  const handleScroll = () => {
    setShadow(window.pageYOffset !== 0);
  };
  
  window.addEventListener("scroll", handleScroll);

  const ScrolltoMarketPlace = () => {
    animateScrollTo(document.querySelector(".crossborder"), {verticalOffset: -headerHeight});
  };
  const ScrolltoInternationalPayment = () => {
    animateScrollTo(document.querySelector(".payment"), {verticalOffset: -headerHeight});
  };
  const ScrolltoLogistics = () => {
    animateScrollTo(document.querySelector(".logistics"), {verticalOffset: -headerHeight});
  };

  const ScrolltoFinance = () => {
    animateScrollTo(document.querySelector(".finance"), {verticalOffset: -headerHeight});
  };
  return (
    <HeaderWrapper shadow={shadow}>
      <Content>
        <LogoWrapper href={`/${locale}`}>
          <img src={Logo} alt="Digitrad Logo" />
        </LogoWrapper>
        <PCNav>
          <LangWrapper>
            <LanguageSwitcher />
          </LangWrapper>
          <Nav>
            <NavItem href="https://standage.co.jp/news" target="_blank">
              {msg("news", locale)}
            </NavItem>
            <NavItem href="https://standage.co.jp/contact" target="_blank">
              {msg("contact", locale)}
            </NavItem>
            <NavItem href="https://standage.co.jp/about" target="_blank">
              {msg("about", locale)}
            </NavItem>
          </Nav>
        </PCNav>
        <MobileNav show={show}>
          <Hamburger onClick={() => setShow(!show)} show={show}>
            <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 24H36V20H0V24ZM0 14H36V10H0V14ZM0 0V4H36V0H0Z"/>
            </svg>
          </Hamburger>
          <MobileNavItem
            onClick={() => {
              window.open("https://standage.co.jp/news");
              setShow(!show);
            }}
            show={show}
          >
            {msg("news", locale)}
          </MobileNavItem>
          <MobileNavItem
            onClick={() => {
              window.open("https://standage.co.jp/contact");
              setShow(!show);
            }}
            show={show}
          >
            {msg("contact", locale)}
          </MobileNavItem>
          <MobileNavItem
            onClick={() => {
              window.open("https://standage.co.jp/about");
              setShow(!show);
            }}
            show={show}
          >
            {msg("about", locale)}
          </MobileNavItem>
          <MobileNavItem
            onClick={() => {
              ScrolltoMarketPlace();
              setShow(!show);
            }}
            show={show}
            dangerouslySetInnerHTML={{__html: msg("crossborder_marketplace", locale)}}
          />
          <MobileNavItem
            onClick={() => {
              ScrolltoInternationalPayment();
              setShow(!show);
            }}
            show={show}
          >
            {msg("international_payment", locale)}
          </MobileNavItem>
          <MobileNavItem
            onClick={() => {
              ScrolltoLogistics();
              setShow(!show);
            }}
            show={show}
          >
            {msg("overseas_logistics", locale)}
          </MobileNavItem>
          <MobileNavItem
            onClick={() => {
              ScrolltoFinance();
              setShow(!show);
            }}
            show={show}
          >
            {msg("financial_tools", locale)}
          </MobileNavItem>
          <MobileNavItem show={show}>
            <LanguageSwitcher show={show} />
          </MobileNavItem>
        </MobileNav>
      </Content>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: fixed;
  height: ${headerHeight}px;
  width: 100%;
  z-index: 100;
  background: ${props => props.shadow ? "white" : "transparent"};
  box-shadow: ${props => props.shadow ? "0 0 10px rgb(0 0 0 / 20%)" : "none"};
`;

const LogoWrapper = styled.a`
  float: left;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
  ${media.md`
    width: 100%;
    position: relative;
    justify-content:center;
  `}
`;

const PCNav = styled.div`
  ${media.md`
    display: none;
  `}
`;

const LangWrapper = styled.div`
  float: right;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
`;

const Nav = styled.div`
  float: right;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
`;

const NavItem = styled.a`
  color: var(--mainGreen);
  font-size: 18px;
  margin-right: 56px;
  line-height: ${headerHeight - 30}px;
  height: ${headerHeight - 30}px;
  text-transform: uppercase;
  :hover {
    border-bottom: 2px solid var(--mainGreen);
    color: var(--mainGreen);
  }
`;

const MobileNav = styled.div`
  display: none;
  position: fixed;
  height: ${props => props.show ? "" : headerHeight}px;
  width: 100%;
  padding-top: 60px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.show ? "rgb(0, 0, 0, 0.7)" : "transparent"};
  ${media.md`
    display: block;
  `}
`;

const Hamburger = styled.div`
  position: absolute;
  left: 31px;
  top: 32.5px;
  width: 36px;
  svg {
    width: 100%;
    fill: currentColor;
    color: ${props => props.show ? "var(--mainGreen)" : "var(--mainBlack)"};
  }
`;

const MobileNavItem = styled.div`
  display: ${props => props.show ? "block" : "none"};
  color: var(--mainGreen);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 15px auto;
`;
