import React from "react";
import styled from "styled-components";

import {media} from "../lib/helpers";

const GreenButton = ({message, href}) => (
  <Button
    href={process.env.REACT_APP_URL + (href ? href : "")}
    rel="noreferrer"
    target="_blank"
  >
    {message}
  </Button>
);

const Button = styled.a`
  display: block;
  text-transform: uppercase;
  width: 400px;
  margin: auto;
  color: var(--mainWhite);
  background-color: var(--mainGreen);
  border: 1px solid var(--mainGreen);
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 60px;
  :hover {
    color: var(--mainGreen);
    background-color: var(--mainWhite);
  }
  ${media.md`
    width: fit-content;
    padding: 0;
    min-width: 246px;
    max-width: 320px;
    line-height: 40px;
  `}
`;

export default GreenButton;