import React, { useState } from "react";
import styled from "styled-components";
import animateScrollTo from "animated-scroll-to";
import {Col, Row} from "antd";

import Logo from "../assets/logo.svg";
import FreightShip from "../assets/ship.png";
import TopTri from "../assets/toptri.svg";
import Up from "../assets/arrowup.svg";
import { headerHeight } from "../Components/Header";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export default function BannerSection({locale}) {
  const [TriIndex, setTriIndex] = useState(13);
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setTriIndex(10);
    } else {
      setTriIndex(13);
    }
  };
  const ScrolltoMarketPlace = () => {
    animateScrollTo(document.querySelector(".crossborder"), {verticalOffset: -headerHeight});
  };
  const ScrolltoInternationalPayment = () => {
    animateScrollTo(document.querySelector(".payment"), {verticalOffset: -headerHeight});
  };
  const ScrolltoLogistics = () => {
    animateScrollTo(document.querySelector(".logistics"), {verticalOffset: -headerHeight});
  };

  const ScrolltoFinance = () => {
    animateScrollTo(document.querySelector(".finance"), {verticalOffset: -headerHeight});
  };

  window.addEventListener("scroll", handleScroll);
  return (
    <Container>
      <TopVector TriIndex={TriIndex}>
        <img src={TopTri} alt="top vector" />
      </TopVector>
      <BannerRow>
        <LeftCol xs={14} lg={16}>
          <TopLogo src={Logo} alt="Digitrad" />
          <BannerText
            dangerouslySetInnerHTML={{__html: msg("international_digital_trading_service", locale)}}
          />
        </LeftCol>
        <Col xs={10} lg={8}>
          <Ship src={FreightShip} alt="Freight Ship" />
        </Col>
      </BannerRow>
      <ButtonSection>
        <StyledSectionButton
          onClick={ScrolltoMarketPlace}
          dangerouslySetInnerHTML={{__html: msg("crossborder_marketplace", locale)}}
        />
        <StyledSectionButton onClick={ScrolltoInternationalPayment}>
          {msg("international_payment", locale)}
        </StyledSectionButton>
        <StyledSectionButton onClick={ScrolltoLogistics}>
          {msg("overseas_logistics", locale)}
        </StyledSectionButton>
        <StyledSectionButton onClick={ScrolltoFinance} last>
          {msg("financial_tools", locale)}
        </StyledSectionButton>
      </ButtonSection>
      <DividerContainer>
        <Line />
        <DividerButton
          href={`${process.env.REACT_APP_URL}`}
          rel="noreferrer"
          target="_blank"
          dangerouslySetInnerHTML={{__html: msg("international_digital_trading_service", locale)}}
        />
        <Line />
      </DividerContainer>
      <ArrowUp>
        <img src={Up} alt="Arrow Up" />
      </ArrowUp>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;
const TopVector = styled.div`
  position: absolute;
  top: -89px;
  left: 192px;
  z-index: ${(props) => props.TriIndex}!important;
  img {
    width: 265px;
    height: 240px;
  }
  ${media.sxl`
    display: none;
  `}
`;

const LeftCol = styled(Col)`
  margin-top: 109px;
`;

const BannerRow = styled(Row)`
  ${media.sxl`
    padding-left: 15px;
    padding-right: 15px;
  `}
`;

const TopLogo = styled.img`
  width: 237px;
  ${media.md`
    width: 150px;
  `}
`;

const BannerText = styled.div`
  color: var(--mainBlack);
  margin-top: 12px;
  font-size: 72px;
  line-height: 98px;
  ${media.lg`
    font-size: 48px;
    line-height: 66px;
  `}
  ${media.md`
    font-size: 24px;
    line-height: 35px;
  `}
`;

const Ship = styled.img`
  width: 100%;
`;

const ButtonSection = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 50px;
  ${media.md`
    margin-bottom: 85px;
  `}
`;

const StyledSectionButton = styled.div`
  display: inline-block;
  cursor: pointer;
  color: var(--mainBlue);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  border: 1px solid var(--mainBlue);
  border-radius: 2px;
  padding: 8px 10px;
  background: transparent;
  margin-top: 4px;
  margin-right: ${props => props.last ? "0" : "4px"};
  :hover {
    background-color: var(--mainBlue);
    color: white;
  }
  ${media.sxl`
    width: 45%;
    margin-top: 0;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 10px;
  `}
  ${media.md`
    width: 95%;
    margin: 0 auto 16px;
  `}
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 40px;
  ${media.md`
    display: none;
  `}
`;

const Line = styled.hr`
  border: 1px solid var(--mainGreen);
  background-color: var(--mainGreen);
  width: 100%;
`;

const DividerButton = styled.a`
  display: flex;
  justify-content: center;
  font-size: 18px;
  padding: 5px 61px;
  background-color: var(--mainGreen);
  border: 1px solid var(--mainGreen);
  color: var(--mainWhite);
  white-space: nowrap;
  :hover {
    text-decoration: none;
    background-color: white;
    color: var(--mainGreen);
  }
`;

const ArrowUp = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 56px;
  ${media.md`
    display: none;
  `}
`;
