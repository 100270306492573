import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import {media} from "../lib/helpers";

export default function LanguageSwitcher({show}) {
  const pathname = useLocation().pathname;
  return (
    <Wrapper show={show}>
      <ButtonNavlink to={`/ja${pathname.slice(3)}`}>JA</ButtonNavlink>
      |
      <ButtonNavlink to={`/en${pathname.slice(3)}`}>EN</ButtonNavlink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: #333333;
  font-size: 12px;
  ${media.md`
    display: ${props => props.show ? "block" : "none"};
    color: ${props => props.show ? "var(--mainGreen)" : ""};
  `}
`;

const ButtonNavlink = styled(Link)`
  color: inherit;
  background: transparent;
  border: none;
  letter-spacing: 1px;
  line-height: 20px;
  font-weight: bold;
  padding-left: 1px;
  padding-right: 1px;
  &:hover {
    color: var(--mainGreen);
    cursor: pointer;
  }
  &:active {
    border-bottom-width: 2px;
  }
`;
