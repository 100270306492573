import React from "react";
import styled from "styled-components";
import {Col, Row} from "antd";

import PaymentImage from "../assets/paymentimage.svg";
import Timer from "../assets/timer.svg";
import Secured from "../assets/secure.svg";
import Percent from "../assets/percent.svg";
import TenMin from "../assets/10Min.svg";
import Calculator from "../Components/Calculator";
import DTSection from "../Components/DTSection";
import GreenButton from "../Components/GreenButton";
import LineEnding from "../Components/LineEnding";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export default function Payment({locale}) {
  return (
    <div className="payment relative">
      <DTSection />
      <Container>
        <Heading dangerouslySetInnerHTML={{__html: msg("payment_heading", locale)}} />
        <StyledRow>
          <MessageCol md={{span: 9, order: 1}} xs={{span: 24, order: 1}}>
            <img src={PaymentImage} alt="Digitrad Payment Service" />
            <Message>
              {msg("payment_designed", locale)}
            </Message>
            <Advantages>
              <Row>
                <Col span={2}>✓</Col>
                <Col span={22}>{msg("small_amount", locale)}</Col>
              </Row>
              <Row>
                <Col span={2}>✓</Col>
                <Col span={22}>{msg("process", locale)}</Col>
              </Row>
              <Row>
                <Col span={2}>✓</Col>
                <Col span={22}>{msg("foreign_exchange", locale)}</Col>
              </Row>
            </Advantages>
          </MessageCol>
          <CalcCol md={{span: 15, order: 2}} xs={{span: 24, order: 3}}>
            <Calculator locale={locale} />
          </CalcCol>
          <IconsCol md={{span: 24, order: 3}} xs={{span: 24, order: 2}}>
            <Row gutter={{xs: 20, md: 40}}>
              <Col md={{span: 6, order: 1}} xs={{span: 12, order: 3}}>
                <FunctionIcon data-aos="flip-right">
                  <img src={Timer} alt="Fast Icon" />
                </FunctionIcon>
                <FunctionHeader>{msg("quick", locale)}</FunctionHeader>
                <FunctionText>
                  {msg("quick_statement", locale)}
                </FunctionText>
              </Col>
              <Col md={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                <FunctionIcon data-aos="flip-right">
                  <img src={Secured} alt="Secure Icon" />
                </FunctionIcon>
                <FunctionHeader>{msg("secure", locale)}</FunctionHeader>
                <FunctionText>
                  {msg("secure_statement", locale)}
                </FunctionText>
              </Col>
              <Col md={{span: 6, order: 3}} xs={{span: 12, order: 4}}>
                <FunctionIcon data-aos="flip-right">
                  <img src={Percent} alt="Low-rates Icon" />
                </FunctionIcon>
                <FunctionHeader>{msg("low_rates", locale)}</FunctionHeader>
                <FunctionText>
                  {msg("low_rates_statement", locale)}
                </FunctionText>
              </Col>
              <Col md={{span: 6, order: 4}} xs={{span: 12, order: 1}}>
                <FunctionIcon data-aos="flip-right">
                  <img src={TenMin} alt="Easy Icon" />
                </FunctionIcon>
                <FunctionHeader>{msg("easy", locale)}</FunctionHeader>
                <FunctionText dangerouslySetInnerHTML={{__html: msg("easy_statement", locale)}} />
              </Col>
            </Row>
          </IconsCol>
        </StyledRow>
        <AdvantagesMobile>
          <Row>
            <Col span={2}>✓</Col>
            <Col span={22}>{msg("small_amount", locale)}</Col>
          </Row>
          <Row>
            <Col span={2}>✓</Col>
            <Col span={22}>{msg("process", locale)}</Col>
          </Row>
          <Row>
            <Col span={2}>✓</Col>
            <Col span={22}>{msg("foreign_exchange", locale)}</Col>
          </Row>
        </AdvantagesMobile>
        <GreenButton message={msg("learn_more", locale)} href={`/${locale}/user/exchange`} />
        <LineEnding />
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin: auto;
`;

const Heading = styled.div`
  margin: auto;
  padding-top: 40px;
  width: 661px;
  font-size: 72px;
  line-height: 85px;
  ${media.lg`
    width: 440px;
    font-size: 48px;
    line-height: 56px;
  `}
  ${media.md`
    width: auto;
    font-size: 36px;
    line-height: 42px;
    margin-left: 135px;
  `}
  ${media.xxs`
    margin-left: 100px;
  `}
`;

const StyledRow = styled(Row)`
  margin-top: 114px;
  display: flex;
  ${media.md`
    margin-top: 23px;
  `}
`;

const MessageCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-right: 27px;
  img {
    order: 1;
    width: 100%;
    max-width: 244px;
  }
  ${media.md`
    padding: 0;
    img {
      order: 2;
      margin: auto;
    }
  `}
`;

const Message = styled.div`
  order: 2;
  margin: 38px 0;
  font-size: 26px;
  line-height: 45px;
  ${media.lg`
    font-size: 20px;
    line-height: 30px;
  `}
  ${media.md`
    order: 1;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin: 0 auto 22px;
  `}
`;

const Advantages = styled.div`
  order: 3;
  font-size: 18px;
  line-height: 45px;
  color: var(--mainGreen);
  ${media.lg`
    font-size: 18px;
    line-height: 30px;
  `}
  ${media.md`
    display: none;
  `}
`;

const AdvantagesMobile = styled.div`
  display: none;
  font-size: 18px;
  line-height: 45px;
  margin: 48px 23px;
  color: var(--mainGreen);
  ${media.md`
    display: block;
  `}
`;

const CalcCol = styled(Col)`
  background-color: var(--mainBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

const IconsCol = styled(Col)`
  margin: auto;
  text-align: center;
  padding: 80px 30px 51px;
  ${media.md`
    padding: 20px 30px;
  `}
`;

const FunctionHeader = styled.div`
  font-weight: bold;
  font-size: 36px;
  margin: auto;
  ${media.md`
    font-size: 20px;
  `}
`;

const FunctionIcon = styled.div`
  width: 100px;
  margin: auto;
  img {
    width: 100%;
  }
  ${media.sm`
    padding-top: 10px;
    padding-bottom: 10px;
    width: 50px;
  `}
`;

const FunctionText = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin: auto;
  ${media.md`
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 10px;
  `}
`;
