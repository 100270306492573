import styled from "styled-components";
import {Layout as AntLayout} from "antd";

import {media} from "../lib/helpers";

export const Wrapper = styled.div`
  background: white;
  width: 100%;
  max-width: 1400px;
  margin-top: 89px;
  min-height: calc(100vh - 89px);
`;

export const Content = styled.div`
  max-width: 1032px;
  width: 100%;
  margin: auto;
  padding: 0 16px;
  ${media.xs`
    padding: 0;
  `}
`;

export const Layout = styled(AntLayout)`
  background-color: var(--mainWhite);
  font-family: 'Raleway', sans-serif;
`;
