import React  from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Header from "./Components/Header";
import SubFooter from "./Components/SubFooter";
import Footer from "./Components/Footer";
import IndexPage from "./pages/IndexPage";
import {Layout, Content} from "./Components/Layout";

import "./App.css";
import 'antd/dist/antd.css';
import "aos/dist/aos.css";

const App = () => {
  const location = useLocation();
  const {pathname} = location;
  const locale = pathname.split("/")[1];
  if (!process.env.REACT_APP_AVAILABLE_LANGUAGES.split("|").includes(locale)) {
    return <Redirect to={`/ja${pathname !== "/" ? pathname : ""}`} />
  }
  return (
    <Layout>
      <Header locale={locale} />
      <Content>
        <Switch>
          <Route exact path={`/${locale}`} component={IndexPage}/>
          <Redirect to={`/${locale}`} />
        </Switch>
        <SubFooter />
      </Content>
      <Footer locale={locale} />
    </Layout>
  );
};

export default App;
