import React from "react";
import styled from "styled-components";

import DT from "../assets/DT.svg";

const DTSection = () => (
  <Background>
    <img src={DT} alt="Icon" />
  </Background>
);

const Background = styled.div`
  background-color: var(--mainBlue);
  width: 195px;
  height: 45px;
  left: -132px;
  top: 20px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: flex-end;

  img {
    right: 0;
    padding: 12px 6px;
  }
`;

export default DTSection;
