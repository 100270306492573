import React from "react";
import styled from "styled-components";
import {Col, Row} from "antd";

import IconOne from "../assets/icon1.svg";
import IconTwo from "../assets/icon2.svg";
import IconThree from "../assets/icon3.svg";
import Desktop from "../assets/desktop.png";
import Logo from "../assets/logo.svg";
import Tick from "../assets/tick.svg";
import DTSection from "../Components/DTSection";
import GreenButton from "../Components/GreenButton";
import LineEnding from "../Components/LineEnding";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export default function CrossBorder({locale}) {
  return (
    <div className="crossborder relative">
      <DTSection />
      <Container>
        <Heading dangerouslySetInnerHTML={{__html: msg("crossborder_heading", locale)}} />
        <SubHeading>{msg("crossborder_subheading", locale)}</SubHeading>
        <Iconsdiv>
          <Row>
            <Col md={8} xs={24}>
              <Section>
                <IconHead data-aos="fade-up">
                  <img src={IconOne} alt="Global Market Icon" />
                </IconHead>
                <IconText>{msg("global_market_entry_support", locale)}</IconText>
              </Section>
            </Col>
            <Col md={8} xs={24}>
              <Section>
                <IconHead data-aos="fade-up">
                  <img src={IconTwo} alt="Shipping Icon" />
                </IconHead>
                <IconText>{msg("door_to_door_shipping_solutions", locale)}</IconText>
              </Section>
            </Col>
            <Col md={8} xs={24}>
              <Section>
                <IconHead data-aos="fade-up">
                  <img src={IconThree} alt="Computer Icon" />
                </IconHead>
                <IconText>{msg("quick_and_easy_product_listing", locale)}</IconText>
              </Section>
            </Col>
          </Row>
        </Iconsdiv>
        <SmallDigitrad>
          <img src={Logo} alt="Digitrad Logo" />
        </SmallDigitrad>
        <Desktopdiv>
          <img src={Desktop} alt="" />
        </Desktopdiv>
        <TickSection>
          <Tickdiv>
            <TickIcon data-aos="fade-up">
              <img src={Tick} alt="digitrad Icon" />
            </TickIcon>
            <TickText>{msg("simple_setup", locale)}</TickText>
          </Tickdiv>
          <Tickdiv>
            <TickIcon data-aos="fade-up" data-aos-delay="200">
              <img src={Tick} alt="digitrad Icon" />
            </TickIcon>
            <TickText>{msg("security_services", locale)}</TickText>
          </Tickdiv>
          <Tickdiv>
            <TickIcon data-aos="fade-up" data-aos-delay="400">
              <img src={Tick} alt="digitrad Icon" />
            </TickIcon>
            <TickText>{msg("free_to_use", locale)}</TickText>
          </Tickdiv>
        </TickSection>
        <GreenButton message={msg("visit_marketplace", locale)} href={`/${locale}`} />
        <LineEnding />
      </Container>
    </div>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Heading = styled.div`
  margin: auto;
  width: 661px;
  font-size: 72px;
  line-height: 85px;
  ${media.lg`
    width: 440px;
    font-size: 48px;
    line-height: 56px;
  `}
  ${media.md`
    width: auto;
    font-size: 36px;
    line-height: 42px;
    margin-left: 135px;
  `}
  ${media.xxs`
    margin-left: 100px;
  `}
`;

const SubHeading = styled.div`
  margin: 10px auto 0;
  width: 661px;
  font-size: 44px;
  font-weight: bold;
  ${media.lg`
    width: 440px;
    font-size: 30px;
  `}
  ${media.md`
    width: auto;
    text-align: center;
    font-size: 24px;
  `}
`;

const Section = styled.div`
  margin-top: 45px;
  ${media.xs`
    margin-top: 30px;
  `}
  ${media.xxs`
    width: 90%;
    margin: auto;
    margin-top: 30px;
  `}
`;

const Iconsdiv = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
  max-width: 1002px;
  margin: auto;
  ${media.lg`
    margin: auto;
  `}
`;

const SmallDigitrad = styled.div`
  display: none;
  text-align: center;
  margin: 37px auto;
  img {
    width: 100%;
    max-width: 256px;
  }
  ${media.sm`
    display: block;
  `}
`;

const IconHead = styled.div`
  text-align: center;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 1;
  position: relative;
`;

const IconText = styled.div`
  font-size: 24px;
  width: auto;
  max-width: 300px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-style: normal;
  margin-top: 13px;
  z-index: 3;
  position: relative;
  ${media.lg`
    font-size: 20px;
  `}
  ${media.md`
    margin: auto;
  `}
`;

const Desktopdiv = styled.div`
  width: auto;
  margin: auto;
  margin-bottom: 80px;
  img {
    width: 100%;
  }
`;

const TickSection = styled.div`
  width: 780px;
  margin: auto;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  ${media.lg`
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
    text-align: center;
  `}
`;

const Tickdiv = styled.div`
  width: 33.3333%;
`;

const TickIcon = styled.div`
  text-align: center;
  width: 105px;
  height: 105px;
  margin: auto;
  ${media.md`
    width: 70px;
    height: 70px;
    img {
      width: 60px
    }
  `}
  ${media.sm`
    width: 42px;
    height: 42px;
    img {
      width: 36px;
    }
  `}
`;

const TickText = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  margin: auto;
  ${media.md`
    font-size: 14px;
  `}
  ${media.sm`
    font-size: 12px;
  `}
`;
