import styled from "styled-components";
import {media} from "../lib/helpers";

const LineEnding = styled.hr`
  margin: 80px auto;
  background-color: var(--mainBlack);
  ${media.sm`
    width: 157px;
    margin: 50px auto 30px;
  `}
`;

export default LineEnding;