import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Aos from "aos";

import Banner from "../Components/BannerSection";
import CrossBorder from "../Components/CrossBorder";
import PaymentCalc from "../Components/Payment";
import Logistics from "../Components/Logistics";
import Finance from "../Components/FinanceTools";
import Diagnosis from "../Components/Diagnosis";

import {Content, Wrapper} from "../Components/Layout";

export default function IndexPage() {
  useEffect(() => {
    setTimeout(() => {
      Aos.init(
        {
          duration: 1200,
          offset: 150,
          once: true,
        },
        []
      );
    }, 1000);
  }, []);
  const location = useLocation();
  const locale = location.pathname.split("/")[1];
  return (
    <Wrapper>
      <Content>
        <Banner locale={locale}/>
        <CrossBorder locale={locale}/>
        <PaymentCalc locale={locale}/>
        <Logistics locale={locale}/>
        <Finance locale={locale}/>
        <Diagnosis locale={locale}/>
      </Content>
    </Wrapper>
  );
};

