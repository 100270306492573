import {css} from "styled-components";
import mapValues from "lodash.mapvalues";

export const dimensionMap = {
  xxs: '400px',
  xs: '479px',
  sm: '575px',
  mm: '719px',
  md: '767px',
  lg: '991px',
  sxl: '1099px',
  xl: '1199px',
  xxl: '1599px',
  xxxl: '>1600px',
};

export const media = mapValues(dimensionMap, value => (...args) => css`
  @media (max-width: ${value}) {
    ${css(...args)}
  }
`);
