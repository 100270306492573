import React from "react";
import styled from "styled-components";

import Lock from "../assets/lock.svg";
import Dots from "../assets/sidepolygon.svg";
import Close from "../assets/close.svg";
import Check from "../assets/check.svg";
import DTSection from "../Components/DTSection";
import GreenButton from "../Components/GreenButton";
import LineEnding from "../Components/LineEnding";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export default function FinanceTools({locale}) {
  return (
    <div className="finance relative">
      <DTSection />
      <SideDots>
        <img src={Dots} alt="Digitrad" />
      </SideDots>
      <Container>
        <Heading dangerouslySetInnerHTML={{__html: msg("finance_heading", locale)}} />
        <Message>
          {msg("finance_message", locale)}
        </Message>
        <Image src={Lock} alt="padlock lock" />
        <ServicePoints>
          <h5>{msg("key_service_points", locale)}</h5>
          <h6>
            <img src={Close} alt="close" />
            <p>{msg("financial_statements", locale)}</p>
          </h6>
          <h6>
            <img src={Close} alt="close" />
            <p>{msg("security", locale)}</p>
          </h6>
          <h6>
            <img src={Close} alt="close" />
            <p>{msg("get_a_loan", locale)}</p>
          </h6>
          <h6>
            <img src={Close} alt="close" />
            <p>{msg("high_rates", locale) + msg("using_digitrad", locale)}</p>
          </h6>
          <h6>
            <img src={Check} alt="check" />
            <p>{msg("collateral", locale)}</p>
          </h6>
          <h6>
            <img src={Check} alt="check" />
            <p>{msg("quick_application", locale)}</p>
          </h6>
          <h6>
            <img src={Check} alt="check" />
            <p>{msg("low_interest_rates", locale)}</p>
          </h6>
        </ServicePoints>
        <GreenButton message={msg("start_saving_now", locale)} href={`/${locale}/user/exchange`} />
        <LineEnding />
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin: auto;
`;

const SideDots = styled.div`
  position: absolute;
  left: -132px;
  top: 60px;
  img {
    width: 265px;
    height: 441px;
  }
  ${media.lg`
    display: none;
  `}
`;

const Heading = styled.div`
  margin: auto;
  padding-top: 40px;
  width: 661px;
  font-size: 72px;
  line-height: 85px;
  ${media.lg`
    width: 440px;
    font-size: 48px;
    line-height: 56px;
  `}
  ${media.md`
    width: auto;
    font-size: 36px;
    line-height: 42px;
    margin-left: 135px;
  `}
  ${media.xxs`
    font-size: 24px;
    line-height: 37px;
  `}
`;

const Message = styled.div`
  width: 661px;
  padding-right: 161px;
  margin: 72px auto 50px;
  font-size: 26px;
  line-height: 45px;
  ${media.lg`
    padding: 0;
    width: 440px;
    font-size: 20px;
    line-height: 30px;
  `}
  ${media.md`
    width: auto;
    margin: 50px auto 35px 135px;
  `};
  ${media.xs`
    font-size: 15px;
    line-height: 20px;
    margin: 40px 10px 20px 135px;
    width: auto;
    max-width: 289px;
  `}
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  top: 125px;
  ${media.lg`
    width: 120px;
    right: auto;
    left: 0;
  `}
  ${media.xs`
    width: 96px;
    left: 18px;
  `}
`;

const ServicePoints = styled.div`
  width: 85%;
  max-width: 661px;
  margin: 72px auto 50px;
  font-size: 18px;
  line-height: 45px;
  font-weight: 300;
  color: var(--mainBlack);
  p {
    margin-bottom: 0px;
  }
  h5 {
    font-weight: bold;
    font-size: 22px;
    color: #30318c;
    margin-bottom: 25px;
  }
  h6 {
    margin-bottom: 25px;
    font-weight: 300;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 24px;
  }
  ${media.xxs`
    h6 {
      font-size: 14px;
    }
  `}
`;
