import React, { useEffect, useState } from "react";
import styled from "styled-components";

import TickCircle from "../assets/tickcircle.svg";
import Dots from "../assets/sidepolygon.svg";
import Arrow from "../assets/arrow.svg";
import DTSection from "../Components/DTSection";
import GreenButton from "../Components/GreenButton";
import LineEnding from "../Components/LineEnding";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export default function Logistics({locale}) {
  const [isMd, setIsMd] = useState(window.innerWidth < 768);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMd(window.innerWidth < 768);
    });
  });
  return (
    <div className="logistics relative">
      <DTSection />
      <SideDots>
        <img src={Dots} alt="Digitrad" />
      </SideDots>
      <Container>
        <Heading>{msg("logistics_heading", locale)}</Heading>
        <Message dangerouslySetInnerHTML={{__html: msg("logistics_sub_heading", locale)}} />
        <DigitradPhrase>
          {msg("logistics_message", locale)}
        </DigitradPhrase>
        <TickSection>
          <TickBar
            no="first"
            data-aos={isMd ? "fade-down" : "fade-right"}
            data-aos-delay={isMd ? "0" : "200"}
            data-aos-duration="200"
          />
          <TickBar
            no="second"
            data-aos={isMd ? "fade-down" : "fade-right"}
            data-aos-delay={isMd ? "0" : "600"}
            data-aos-duration="200"
          />
          <TickItem
            no="first" 
            data-aos={isMd ? "fade-down" : "fade-right"}
            data-aos-duration="200"
          >
            <img src={TickCircle} alt="tick" />
            <TickText>{msg("confirmation_of_import_and_export", locale)}</TickText>
          </TickItem>
          <TickItem
            data-aos={isMd ? "fade-down" : "fade-right"} 
            data-aos-delay={isMd ? "0" : "400"}
            data-aos-duration="200"
          >
            <img src={TickCircle} alt="tick" />
            <TickText>{msg("auto_generarion", locale)}</TickText>
          </TickItem>
          <TickItem
            no="last"
            data-aos={isMd ? "fade-down" : "fade-right"} 
            data-aos-delay={isMd ? "0" : "800"}
            data-aos-duration="200"
          >
            <img src={TickCircle} alt="tick" />
            <TickText>{msg("chat_with_logistics_company", locale)}</TickText>
          </TickItem>
        </TickSection>
        <BlueBox>{msg("match_for_lowest_price", locale)}</BlueBox>
        <ArrowWrapper>
          <img src={Arrow} alt="arrow" />
        </ArrowWrapper>
        <WhiteBox>{msg("confirmation_of_dimensions", locale)}</WhiteBox>
        <GreenButton message={msg("try_now", locale)} href={`/${locale}/user/forwarding`} />
        <LineEnding />
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin: auto;
`;

const SideDots = styled.div`
  position: absolute;
  right: 0;
  img {
    width: 265px;
    height: 441px;
  }
  ${media.lg`
    display: none;
  `}
`;

const Heading = styled.div`
  margin: auto;
  padding-top: 40px;
  width: 661px;
  font-size: 72px;
  line-height: 85px;
  ${media.lg`
    width: 440px;
    font-size: 48px;
    line-height: 56px;
  `}
  ${media.md`
    width: auto;
    font-size: 36px;
    line-height: 42px;
    margin-left: 135px;
  `}
  ${media.xxs`
    margin-left: 100px;
  `}
`;

const Message = styled.div`
  width: 661px;
  margin: 72px auto 50px;
  font-size: 26px;
  line-height: 45px;
  ${media.lg`
    width: 440px;
    font-size: 24px;
    line-height: 44px;
  `}
  ${media.md`
    width: auto;
    font-size: 20px;
    line-height: 30px;
    margin: 50px auto 35px 135px;
  `};
  ${media.xxs`
    font-size: 15px;
    line-height: 20px;
    margin: 40px auto 20px;
    width: auto;
    max-width: 289px;
  `}
`;

const DigitradPhrase = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: var(--mainBlue);
  margin-bottom: 100px;
  ${media.md`
    font-size: 25px;
    line-height: 45px;
    margin-bottom: 60px;
  `}
  ${media.xxs`
    width: 289px;
    margin: 0 auto 20px;
  `}
`;

const TickSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 42px;
  ${media.md`
    flex-direction: column;
  `}
`;

const TickItem = styled.div`
  width: 160px;
  margin-left: ${props => props.no === "first" ? "0" : "auto"};
  margin-right: ${props => props.no === "last" ? "0" : "auto"};
  text-align: center;
  img {
    position: relative;
  }
  ${media.md`
    width: auto;
    height: 100px;
    margin: 0;
    margin-bottom: ${props => props.no !== "last" ? "63px" : "0"};
    display: flex;
    img {
      display: block;
      margin-left: 18px;
      margin-right: 30px;
    }
  `}
`;

const TickText = styled.div`
  font-size: 18px;
  line-height: 29px;
  margin-top: 29px;
  ${media.md`
    width: 100%;
    margin: 0;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${media.xxs`
    font-size: 20px;
    line-height: 29px;
  `}
`;

const TickBar = styled.div`
  width: calc(50% - 180px);
  height: 54px;
  padding-top: 46px;
  background-clip: content-box !important;
  background: var(--mainGreen);
  position: absolute;
  ${props => props.no === "first" ? "left" : (props.no === "second" ? "right" : "")}: 130px;
  ${media.md`
    height: 63px;
    width: 8px;
    padding: 0;
    left: 64px;
    top: ${props => props.no === "first" ? "100px" : (props.no === "second" ? "263px" : "")};
  `}
`;


const BlueBox = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 31px;
  color: var(--mainWhite);
  background: var(--mainBlue);
  width: fit-content;
  max-width: 90%;
  min-height: 60px;
  margin: 0 auto 10px;
  padding: 6px;
  border: 1px solid var(--mainBlue);
`;

const ArrowWrapper = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

const WhiteBox = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 31px;
  color: var(--mainBlue);
  background: var(--mainWhite);
  width: fit-content;
  max-width: 90%;
  min-height: 60px;
  margin: 0 auto 45px;
  padding: 6px;
  border: 1px solid var(--mainBlue);
`;