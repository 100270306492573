const messages = {
  // Header
  news: {
    ja: "ニュース",
    en: "News",
  },
  contact: {
    ja: "お問い合わせ",
    en: "Contact",
  },
  about: {
    ja: "会社概要",
    en: "About",
  },

  // BannerSection
  international_digital_trading_service: {
   ja: `<span class="ib">新しい</span><span class="ib">国際販売</span>の<span class="ib">ご提案</span>`,
   en: "International Digital Trading Service",
  },
  crossborder_marketplace: {
    ja: `<span class="ib">海外</span><span class="ib">マーケット</span><span class="ib">プレイス</span>`,
    en: "Crossborder Marketplace",
  },
  international_payment: {
    ja: "国際送金",
    en: "International Payment",
  },
  overseas_logistics: {
    ja: "国際物流",
    en: "Overseas Logistics",
  },
  financial_tools: {
    ja: "ファイナンス",
    en: "Financial Tools",
  },

  // CrossBorder
  crossborder_heading: {
    ja: `海外<br><span class="ib">マーケット</span><span class="ib">プレイス</span>`,
    en: `Crossborder<br>Marketplace`,
  },
  crossborder_subheading: {
    ja: "こんなお悩みございませんか？",
    en: "An all inclusive platform",
  },
  global_market_entry_support: {
    ja: "製品には自信があるのに、海外展開ができない",
    en: "Global market entry support",
  },
  door_to_door_shipping_solutions: {
    ja: "輸出には慣れてないので、不安である",
    en: "Door to door shipping solutions",
  },
  quick_and_easy_product_listing: {
    ja: "インターネットでの販売方法がわからない",
    en: "Quick and easy product listing",
  },
  simple_setup: {
    ja: "簡単掲載",
    en: "Simple setup",
  },
  security_services: {
    ja: "安心サポート",
    en: "Security services",
  },
  free_to_use: {
    ja: "今なら掲載無料",
    en: "Free to use",
  },
  visit_marketplace: {
    ja: "試してみる",
    en: "Visit marketplace",
  },

  // Payment
  payment_heading: {
    ja: "国際送金",
    en: `International<br>Payment`,
  },
  payment_designed: {
    ja: "貿易代金決済・国際送金で、以下のような悩みを抱えていらっしゃる企業様へ",
    en: "Designed for companies with trade payment settlement and international remittance challenges",
  },
  small_amount: {
    ja: "少額の送金なのに、手数料が高い",
    en: "少額の送金なのに、手数料が高い",
  },
  process: {
    ja: "毎度手続きが煩雑",
    en: "毎度手続きが煩雑",
  },
  foreign_exchange: {
    ja: "外貨規制が頻繁に起こり、いつ送金、着金できるかわからない",
    en: "外貨規制が頻繁に起こり、いつ送金、着金できるかわからない",
  },
  instant_estimate: {
    ja: "送金見積もり",
    en: "Instant Estimate",
  },
  available_currencies: {
    ja: "利用できる通貨",
    en: "Available Currencies",
  },
  you_send: {
    ja: "送金額",
    en: "You send",
  },
  you_receive: {
    ja: "受取額",
    en: "You receive",
  },
  transfer_fee: {
    ja: "送金手数料",
    en: "Transfer Fee (Fixed)",
  },
  amount_we_will_convert: {
    ja: "送金する金額",
    en: "Amount We will convert",
  },
  guaranteed_rates: {
    ja: "保証レート",
    en: "Guaranteed Rates (10 mins)",
  },
  quick: {
    ja: "即時",
    en: "Quick",
  },
  secure: {
    ja: "安全",
    en: "Secure",
  },
  low_rates: {
    ja: "安価",
    en: "Low rates",
  },
  easy: {
    ja: "簡単",
    en: "Easy",
  },
  quick_statement: {
    ja: "最短1時間以内で着金",
    en: "Confirmation of deposit within one hour",
  },
  secure_statement: {
    ja: "独自のエスクロー機能による安全な送金を実現。",
    en: "Safe remittance through escrow service",
  },
  low_rates_statement: {
    ja: "手数料0.7%～",
    en: "Commissions from 0.7%~",
  },
  easy_statement: {
    ja: "以下問い合わせより送金額を入力するだけ。10分程度で手続き完了。",
    en: `Sign-up<br>Guided inquiry submission in under 10 minutes`,
  },
  learn_more: {
    ja: "試してみる",
    en: "Learn more",
  },

  // Logistics
  logistics_heading: {
    ja: "海外物流",
    en: "Overseas Logistics",
  },
  logistics_sub_heading: {
    ja: "輸出業務は、本当に大変です。<br>輸出・輸入基準の確認、各種船積書類<br>の作成、物流会社とのやり取り・・・",
    en: "Exporting product can be a frustrating experience. DiGiTRAD can halve the work, and increase profit and productivity,",
  },
  logistics_message: {
    ja: "その業務、DiGiTRADで半分に減らしませんか？",
    en: "Increase your workflow efficiency",
  },
  confirmation_of_import_and_export: {
    ja: "輸出、輸入の可否を一緒にお調べ",
    en: "Confirmation of import and export regulation details",
  },
  auto_generarion: {
    ja: "輸出書類は自動作成",
    en: "Auto-generation of export documentation",
  },
  chat_with_logistics_company: {
    ja: "物流会社とのやり取りはチャットで楽々",
    en: "Chat based communication with logistic company (English support available)",
  },
  match_for_lowest_price: {
    ja: "そして・・・価格にも自信あり！！",
    en: "Match for lowest price",
  },
  confirmation_of_dimensions: {
    ja: "出荷製品のサイズ、重量を入力すると即時で概算物流費が分かります",
    en: "Confirmation of final dimensions for immediate estimate generation",
  },
  try_now: {
    ja: "試してみる",
    en: "Try now",
  },
  
  // FinanceTools
  finance_heading: {
    ja: "ファイナンス",
    en: `Trading<br>Finance Service`,
  },
  finance_message: {
    ja: "貿易ビジネスを開始した多くの企業が、【キャッシュフローが悪くなった】と言っています。そのため、貿易企業の実に3割以上が貿易ファイナンスサービスを活用しています。",
    en: "Legacy financial companies have seen their trading business becoming increasingly challenged with cash flow profitability. In order to reduce these hurdles, over 30% of trading companies utilize financial services like DiGiTRAD. ",
  },
  key_service_points: {
    ja: "Key Service Points",
    en: "Key Service Points",
  },
  financial_statements: {
    ja: "財務諸表を厳密に審査される...",
    en: "財務諸表を厳密に審査される...",
  },
  security: {
    ja: "担保設定が必要...",
    en: "担保設定が必要...",
  },
  get_a_loan: {
    ja: "融資迄に長い時間がかかる...",
    en: "融資迄に長い時間がかかる...",
  },
  high_rates: {
    ja: "金利が高い...",
    en: "金利が高い...",
  },
  using_digitrad: {
    ja: "デジトラッドファイナンスサポートを御活用頂くと、",
    en: "デジトラッドファイナンスサポートを御活用頂くと、",
  },
  collateral: {
    ja: "担保は発注書のみ",
    en: "Collateral for purchase order only",
  },
  quick_application: {
    ja: "迅速、簡便な申請＆承認",
    en: "Quick application and approval",
  },
  low_interest_rates: {
    ja: "低金利を実現",
    en: "Low interest rates",
  },
  start_saving_now: {
    ja: "試してみる",
    en: "Start saving now",
  },

  // Diagnosis
  diagnosis_message: {
    ja: "実は、多くの日本製品がアフリカで求められています！",
    en: "実は、多くの日本製品がアフリカで求められています！",
  },
  diagnosis_heading: {
    ja: "１分で出来る、アフリカ貿易診断！",
    en: "１分で出来る、アフリカ貿易診断！",
  },
  diagnosis_text: {
    ja: "アフリカへ製品・サービスを展開したい企業様、弊社が日本国内で貴社製品を購入し、アフリカでの貿易ビジネスを構築いたします。貴社製品・サービスがアフリカで売れるかどうか・・・まずは以下より、アフリカ適性診断をしてみましょう！",
    en: "アフリカへ製品・サービスを展開したい企業様、弊社が日本国内で貴社製品を購入し、アフリカでの貿易ビジネスを構築いたします。貴社製品・サービスがアフリカで売れるかどうか・・・まずは以下より、アフリカ適性診断をしてみましょう！",
  },
};

export const msg = (id, locale) => (id && messages[id] ? messages[id][locale] : (id || ""));
