import React from 'react';
import styled from 'styled-components';

import {media} from "../lib/helpers";

export default function Footer({locale}) {
  return (
    <FooterWrapper>
      <FooterContainer>
        <TextContainer>
          DiGiTRAD is a registered trademark and product by STANDAGE, |&nbsp;&nbsp;
          <a href={`${process.env.REACT_APP_URL}/${locale}/terms`} rel="noreferrer" target="_blank">Terms and Conditions</a>
        </TextContainer>
        <IconContainer>
          <a href="https://wa.me/message/JRO5UQOD3LB3D1" rel="noreferrer" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5044 3.48953C19.3978 2.37945 18.0808 1.49934 16.6296 0.900305C15.1785 0.301272 13.6223 -0.00473655 12.0514 5.54206e-05C5.46225 5.54206e-05 0.101685 5.33549 0.101685 11.8905C0.0952968 13.978 0.64514 16.0299 1.69514 17.8369L0 24L6.33554 22.3446C8.08798 23.2946 10.0512 23.7932 12.0466 23.7952H12.0514C18.6358 23.7952 23.9952 18.4597 23.9999 11.9C24.005 10.3376 23.6986 8.78978 23.0986 7.3461C22.4986 5.90242 21.6168 4.5916 20.5044 3.48953ZM17.8965 16.1433C17.6488 16.8365 16.4298 17.5058 15.8831 17.5522C15.3316 17.6034 14.8184 17.7987 12.299 16.8138C9.26047 15.6229 7.34641 12.524 7.19568 12.324C7.04614 12.1287 5.98025 10.7138 5.98025 9.25371C5.98025 7.78884 6.75185 7.07189 7.02341 6.77416C7.29856 6.47642 7.62155 6.40139 7.82253 6.40139C8.01872 6.40139 8.2197 6.40139 8.39196 6.41092C8.6073 6.41568 8.84057 6.42878 9.06547 6.92303C9.33105 7.50897 9.91125 8.9786 9.98662 9.12747C10.0608 9.27633 10.1122 9.45379 10.0093 9.6491C9.91125 9.84918 9.85981 9.97066 9.71506 10.1469C9.56552 10.3184 9.40163 10.5328 9.26645 10.6626C9.11691 10.8115 8.96259 10.9746 9.13486 11.2724C9.30832 11.5701 9.90647 12.5419 10.7941 13.3279C11.9342 14.3426 12.8972 14.6546 13.1963 14.8035C13.4953 14.9524 13.6676 14.9286 13.8411 14.7285C14.0181 14.5332 14.5887 13.8639 14.7897 13.5661C14.9859 13.2684 15.1869 13.3196 15.4572 13.4173C15.7324 13.5149 17.2002 14.2354 17.4993 14.3843C17.7984 14.5332 17.9946 14.6082 18.0699 14.7297C18.1441 14.8547 18.1441 15.4502 17.8965 16.1433Z"/>
            </svg>
          </a>
          <a href="https://www.facebook.com/digitrad.ng/" rel="noreferrer" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 12.0301C24 5.38947 18.624 0 12 0C5.376 0 0 5.38947 0 12.0301C0 17.8526 4.128 22.7008 9.6 23.8195V15.6391H7.2V12.0301H9.6V9.02256C9.6 6.70075 11.484 4.81203 13.8 4.81203H16.8V8.42105H14.4C13.74 8.42105 13.2 8.96241 13.2 9.62406V12.0301H16.8V15.6391H13.2V24C19.26 23.3985 24 18.2737 24 12.0301Z"/>
            </svg>
          </a>
          <a href="https://www.instagram.com/digitrad.world/" rel="noreferrer" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.7266 0.914062H5.27344C2.775 0.914062 0.75 2.93906 0.75 5.4375V18.5625C0.75 21.0609 2.775 23.0859 5.27344 23.0859H18.7266C21.225 23.0859 23.25 21.0609 23.25 18.5625V5.4375C23.25 2.93906 21.225 0.914062 18.7266 0.914062ZM12 17.2969C9.075 17.2969 6.70312 14.925 6.70312 12C6.70312 9.075 9.075 6.70312 12 6.70312C14.925 6.70312 17.2969 9.075 17.2969 12C17.2969 14.925 14.925 17.2969 12 17.2969ZM18.8297 6.56719C18.2063 6.56719 17.7 6.06094 17.7 5.4375C17.7 4.81406 18.2063 4.30781 18.8297 4.30781C19.4531 4.30781 19.9594 4.81406 19.9594 5.4375C19.9594 6.06094 19.4531 6.56719 18.8297 6.56719Z"/>
            </svg>
          </a>
          <a href="https://www.linkedin.com/showcase/digitrad-world/" rel="noreferrer" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.829 0H2.17099C0.972 0 0 0.972 0 2.17099V21.8289C0 23.028 0.972 24 2.17099 24H21.8289C23.028 24 24 23.028 24 21.8289V2.17099C24 0.972 23.028 0 21.829 0ZM7.42662 20.7232C7.42662 21.0721 7.14377 21.355 6.79483 21.355H4.10544C3.7565 21.355 3.47365 21.0721 3.47365 20.7232V9.4494C3.47365 9.10046 3.7565 8.81761 4.10544 8.81761H6.79483C7.14377 8.81761 7.42662 9.10046 7.42662 9.4494V20.7232ZM5.45014 7.75489C4.0391 7.75489 2.8952 6.61099 2.8952 5.19996C2.8952 3.78892 4.0391 2.64503 5.45014 2.64503C6.86117 2.64503 8.00507 3.78892 8.00507 5.19996C8.00507 6.61099 6.86124 7.75489 5.45014 7.75489ZM21.4813 20.7741C21.4813 21.0949 21.2212 21.355 20.9004 21.355H18.0145C17.6937 21.355 17.4335 21.0949 17.4335 20.7741V15.486C17.4335 14.6972 17.6649 12.0292 15.372 12.0292C13.5934 12.0292 13.2327 13.8553 13.1602 14.6749V20.7741C13.1602 21.0949 12.9002 21.355 12.5793 21.355H9.78817C9.46737 21.355 9.20727 21.0949 9.20727 20.7741V9.39851C9.20727 9.07772 9.46737 8.81761 9.78817 8.81761H12.5793C12.9001 8.81761 13.1602 9.07772 13.1602 9.39851V10.3821C13.8197 9.39236 14.7998 8.62844 16.8866 8.62844C21.5077 8.62844 21.4813 12.9457 21.4813 15.3178V20.7741Z"/>
            </svg>
          </a>
          <a href="https://mobile.twitter.com/digitrad_world" rel="noreferrer" target="_blank">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.0769 2.98086C22.2443 3.35062 21.3638 3.59506 20.4628 3.70657C21.4134 3.12523 22.1257 2.21068 22.4677 1.13258C21.5744 1.67693 20.5966 2.06046 19.5766 2.26649C18.8949 1.50969 17.9871 1.00591 16.9955 0.834188C16.0039 0.662463 14.9847 0.832495 14.0978 1.31762C13.2108 1.80274 12.5062 2.57552 12.0944 3.51475C11.6827 4.45398 11.5872 5.50653 11.8228 6.50734C10.0166 6.41382 8.24986 5.93239 6.6373 5.09432C5.02473 4.25625 3.60243 3.0803 2.46279 1.64284C2.06306 2.3574 1.85302 3.16662 1.85356 3.99004C1.85214 4.75474 2.03546 5.50792 2.38719 6.18254C2.73893 6.85716 3.24816 7.43228 3.86956 7.8567C3.14732 7.83658 2.44051 7.63819 1.80925 7.2784V7.3351C1.81466 8.40652 2.18143 9.44315 2.84751 10.2696C3.51358 11.0961 4.43809 11.6617 5.46463 11.8708C5.06947 11.9939 4.6592 12.0588 4.24617 12.0635C3.96027 12.0601 3.67507 12.0336 3.39325 11.9841C3.68558 12.9058 4.25129 13.7113 5.01167 14.2884C5.77205 14.8656 6.68927 15.1858 7.63571 15.2045C6.03754 16.4918 4.06438 17.1943 2.03079 17.2002C1.66053 17.2014 1.29056 17.1787 0.923096 17.1321C2.99939 18.5045 5.41901 19.233 7.89048 19.2299C9.59599 19.248 11.2879 18.9181 12.8675 18.2593C14.447 17.6006 15.8825 16.6263 17.0901 15.3933C18.2977 14.1603 19.2531 12.6933 19.9007 11.078C20.5482 9.4628 20.8749 7.73166 20.8616 5.98573C20.8616 5.79297 20.8616 5.58886 20.8616 5.38476C21.7308 4.7212 22.4804 3.90775 23.0769 2.98086Z"/>
            </svg>
          </a>
        </IconContainer>
      </FooterContainer>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background-color: var(--mainBlack);
  height: auto;
  min-height: 86px;
  display: flex;
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  ${media.lg`
    display: block;
  `}
`;

const TextContainer = styled.div`
  align-self: center;
  color: white;
  font-size: 16px;
  line-height: 24px;
  a {
    display: inline-block;
    color: var(--mainWhite);
    :hover {
      color: var(--mainGreen);
    }
  }
  ${media.lg`
    margin: 10px 0;
  `}
`;

const IconContainer = styled.div`
  display: flex;
  width: 192px;
  height: 24px;
  justify-content: space-between;
  svg {
    width: 24px;
    fill: currentColor;
    color: var(--mainWhite);
    :hover {
      color: var(--mainGreen);
    }
  }
  ${media.lg`
    margin: 10px 0 15px;
  `}
`;