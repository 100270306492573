import React from "react";
import styled from "styled-components";
import animateScrollTo from "animated-scroll-to";

import Logo from "../assets/logo.svg";

import {media} from "../lib/helpers";

export default function SubFooter() {
  const Scroll = () => {
    animateScrollTo(0);
  };
  return (
    <LogoContainer onClick={Scroll} data-aos="flip-right">
      <img src={Logo} alt="digitrad Logo" />
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  text-align: center;
  img {
    width: 228.96px;
    margin-top: 247px;
    margin-bottom: 48px;
    cursor:pointer;
  }
  ${media.md`
    padding-top: 126px;
    img {
      display: none;
    }
  `}
`;
