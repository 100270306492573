import React, { Component } from "react";
import { ModalBody, Spinner } from "reactstrap";
import axios from "axios";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faWallet } from "@fortawesome/free-solid-svg-icons";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import InputModal from "./InputModal";
import OutputModal from "./OutputModal";
import ngnflag from "../assets/flags/nigeria.svg";
import DT from "../assets/DT.svg";
import jpyflag from "../assets/flags/japan.svg";
import usdflag from "../assets/flags/usa.svg";
import btcflag from "../assets/flags/btc.svg";
import usdcflag from "../assets/flags/usdc.svg";

import { media } from "../lib/helpers";
import { msg } from "../messages";

const options = [
  {
    symbol: "JPY",
    text: "Japanese Yen",
    flagImg: jpyflag,
    flagAlt: "Japanese Flag",
  },
  {
    symbol: "BTC",
    text: "Bitcoin",
    flagImg: btcflag,
    flagAlt: "Bitcoin Flag",
  },
  {
    symbol: "USDC",
    text: "USD Coin",
    flagImg: usdcflag,
    flagAlt: "USDC Flag",
  },
  {
    symbol: "NGN",
    text: "Nigerian Naira",
    flagImg: ngnflag,
    flagAlt: "Nigerian Flag",
  },
  {
    symbol: "USD",
    text: "USD United States Dollar",
    flagImg: usdflag,
    flagAlt: "Bitcoin Flag",
  },
];

export default class Calculator extends Component {
  constructor() {
    super();
    this.state = {
      from_currency: "JPY",
      to_currency: "NGN",
      from_currency_flag: jpyflag,
      to_currency_flag: ngnflag,
      from_amount: 100000,
      to_amount: "",

      modalone: false,
      modaltwo: false,

      rate_json: 0,
      query: {},

      loading: false,
    };
    this.input = "";
    this.calculate = debounce(this.calculate, 500);
  }

  componentDidMount = () => {
    this.handleInput(this.state.from_amount, "from");
  };

  fetchApi = ({ from_currency, to_currency, from_amount, to_amount }) => {
    const queryString = `from_currency=${from_currency}&to_currency=${to_currency}&from_amount=${from_amount}`;
    this.setState({ ...this.state, loading: true });
    return axios({
      method: "get",
      url: `https://app.digitrad.world/api/master/currency/rate?${queryString}`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.setState({ ...this.state, loading: false });
      return res.data;
    });
  };

  getRate = async ({ from_amount, to_amount, from_currency, to_currency }) => {
    try {
      const result = await this.fetchApi({
        from_currency,
        to_currency,
        from_amount,
        to_amount,
      });
      this.setState({
        ...this.state,
        [from_amount ? "to_amount" : "from_amount"]: result.result,
        rate_json: result,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  calculate = () => {
    const { query } = this.state;
    if (!query.from_currency || !query.to_currency) {
      return;
    }
    const timeOutId = setTimeout(() => this.getRate(query), 500);
    return () => clearTimeout(timeOutId);
  };

  handleInput = async (value, getKey) => {
    this.setState(
      {
        from_amount: value,
      },
      async () => {
        const { from_amount, to_amount, from_currency, to_currency } =
          this.state;
        if (!from_currency || !to_currency) {
          return;
        }
        const query = {
          from_currency,
          to_currency,
        };

        if (getKey === "to") {
          query.to_amount = to_amount;
        } else {
          query.from_amount = from_amount;
        }

        this.setState({
          ...this.state,
          query,
        });
        this.calculate();
      }
    );
  };
  toggleModalOne = () => {
    this.setState({ modalone: !this.state.modalone });
  };
  toggleModalTwo = () => {
    this.setState({ modaltwo: !this.state.modaltwo });
  };

  selectOption = (currency, flag, key) => {
    this.setState({
      [key]: currency,
      [`${key}_flag`]: flag,
      modalone: false,
      modaltwo: false,
    });
    const { to_amount, from_amount, from_currency, to_currency } = this.state;
    if (from_currency && to_currency) {
      if (from_amount) {
        this.handleInput(from_amount, "from");
      } else if (to_amount) {
        this.handleInput(to_amount, "to");
      }
    }
  };

  render() {
    return (
      <Wrapper id="payment">
        <CalculatorHeader>
          <img src={DT} alt="Digitrad Icon" />
          <HeaderText>{msg("instant_estimate", this.props.locale)}</HeaderText>
        </CalculatorHeader>
        <SendSection>
          <InputSection>
            <p>{msg("you_send", this.props.locale)}</p>
            <input
              type="number"
              value={this.state.from_amount}
              onChange={(e) => this.handleInput(e.target.value, "from")}
              autoComplete="off"
              placeholder="1000"
            />
          </InputSection>
          <StyledSelect>
            <StyledButton onClick={this.toggleModalOne}>
              <img src={this.state.from_currency_flag} alt="Currencies Flags" />{" "}
              {this.state.from_currency}
              <FontAwesomeIcon icon={faChevronDown} />
            </StyledButton>
          </StyledSelect>
        </SendSection>

        <InputModal
          inputmodal={this.state.modalone}
          toggleInputModal={this.toggleModalOne}
          modalheading={<>{msg("available_currencies", this.props.locale)}</>}
          modalbody={
            <StyledModalBody>
              <ul>
                {options.map((option) => (
                  <li
                    onClick={() =>
                      this.selectOption(
                        option.symbol,
                        option.flagImg,
                        "from_currency"
                      )
                    }
                    key={option.symbol}
                  >
                    <img src={option.flagImg} alt={option.flagAlt} />
                    {option.text}
                  </li>
                ))}
              </ul>
            </StyledModalBody>
          }
        />
        <RatesSection>
          <LineSection>
            <StyledLine />
          </LineSection>

          <FixedRates className="my-auto">
            <RatesItemsWrapper>
              <IconContainer>
                <StyledLineIcons icon={faCheck} />
              </IconContainer>

              <Amount className="roboto">
                {this.state.loading ? (
                  <Spinner size="sm" color="primary" />
                ) : (
                  this.state.rate_json.commission
                )}{" "}
                {this.state.from_currency}
              </Amount>
              <AmountDesc>{msg("transfer_fee", this.props.locale)}</AmountDesc>
            </RatesItemsWrapper>
            <RatesItemsWrapper>
              <IconContainer>
                <StyledLineIcons icon={faWallet} />
              </IconContainer>
              <Amount className="roboto">
                {this.state.loading ? (
                  <Spinner size="sm" color="primary" />
                ) : (
                  this.state.rate_json.amount_to_convert
                )}{" "}
                {this.state.from_currency}
              </Amount>
              <AmountDesc>
                {msg("amount_we_will_convert", this.props.locale)}
              </AmountDesc>
            </RatesItemsWrapper>
            <RatesItemsWrapper>
              <IconContainer>
                <StyledLineIcons icon={faExchangeAlt} />
              </IconContainer>
              <Amount className="roboto">
                {this.state.loading ? (
                  <Spinner size="sm" color="primary" />
                ) : (
                  this.state.rate_json.rate
                )}
              </Amount>
              <AmountDesc>
                {msg("guaranteed_rates", this.props.locale)}
              </AmountDesc>
            </RatesItemsWrapper>
          </FixedRates>
        </RatesSection>

        <ReceiveSection>
          <OutputSection className="roboto">
            <p>{msg("you_receive", this.props.locale)}</p>
            {this.state.loading ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <input
                type="number"
                autoComplete="off"
                disabled
                value={this.state.to_amount}
              />
            )}
          </OutputSection>
          <StyledSelect>
            <StyledButton onClick={this.toggleModalTwo}>
              <img src={this.state.to_currency_flag} alt="Currencies Flags" />{" "}
              {this.state.to_currency}
              <FontAwesomeIcon icon={faChevronDown} />
            </StyledButton>
          </StyledSelect>
        </ReceiveSection>
        <OutputModal
          outputmodal={this.state.modaltwo}
          toggleOutputModal={this.toggleModalTwo}
          modalheading={<>{msg("available_currencies", this.props.locale)}</>}
          modalbody={
            <StyledModalBody>
              <ul>
                {options.map((option) => (
                  <li
                    onClick={() =>
                      this.selectOption(
                        option.symbol,
                        option.flagImg,
                        "to_currency"
                      )
                    }
                    key={option.symbol}
                  >
                    <img src={option.flagImg} alt={option.flagAlt} />
                    {option.text}
                  </li>
                ))}
              </ul>
            </StyledModalBody>
          }
        />
      </Wrapper>
    );
  }
}

const SendSection = styled.div`
  display: flex;
  ${media.xs`
    width: 100%;
    margin: auto;
  `}
`;

const Wrapper = styled.div`
  @media (max-width: 426px) {
    width: auto;
    max-width: 290px;
    margin: auto;
  }
`;

const ReceiveSection = styled.div`
  display: flex;
  margin-top: -15px;
  ${media.xs`
    width: 100%;
    margin: auto;
    margin-top: -15px;
  `}
`;

const InputSection = styled.div`
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;

  input {
    border-left-width: 1px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 36px;
    font-size: 22px;
    line-height: 32px;
    outline: 0;
    border: none;
    font-family: "Roboto", sans-serif;
    -moz-outline-style: none;
    :active {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
    :focus {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
    ${media.xs`
      width: auto;
      max-width: 150px;
    `}
  }
  p {
    margin-bottom: 5px;

    padding-top: 7px;
    padding-left: 3px;
    height: 20px;
    color: var(--color-accent);
  }
  ${media.xs`
    width: 100%;
    max-width: 200px;
    font-size: 16px;
  `}
`;

const OutputSection = styled.div`
  width: 100%;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;

  input {
    &:disabled {
      background: white;
    }
    border-left-width: 1px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 36px;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    line-height: 32px;
    outline: 0;
    border: none;
    -moz-outline-style: none;
    :active {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
    :focus {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }
    ${media.xs`
      width: 100%;
      max-width: 150px;
    `}
  }
  p {
    margin-bottom: 5px;

    padding-top: 7px;
    padding-left: 3px;
    height: 20px;
    color: var(--color-accent);
  }
  ${media.xs`
    width: 100%;
    max-width: 200px;
    font-size: 16px;
  `}
`;

const StyledSelect = styled.div`
  background-color: #2e4369;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  height: 70px;
  width: 120px;
  background-color: black;
  border: #2e4369;
  justify-content: space-around;
  font-weight: bold;
  color: white;
  outline: 0;
  border: none;
  -moz-outline-style: none;
  img {
    width: 30px;
  }
  :hover {
    border: 0.5px solid white;
  }
  :focus {
    outline: 0;
    border: 0.5px solid white;
  }
  ${media.xs`
    width: 100px;
    img {
      width: 20px;
    }
  `}
`;

const StyledModalBody = styled(ModalBody)`
  padding-top: 0px;
  h5 {
    font-size: 14px !important;
  }
  button {
    font-size: 14px !important;
  }
  ul {
    text-decoration: none;
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  li {
    padding: 13px 16px 11px;
    width: 100%;
    font-size: 0.9375rem;
    :hover {
      cursor: pointer;
      background-color: var(--mainBlue);
      outline: 0;
      color: white;
    }
  }
  img {
    width: 30px;
    padding-right: 10px;
  }
`;

const RatesSection = styled.div`
  display: flex;
  margin-top: -15px;
  ${"" /* align-items:center; */}
`;

const RatesItemsWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  text-align: center;
  margin-right: 20px;
  margin-left: -30px;
  z-index: 10;
  @media (max-width: 425px) {
    margin-left: -25px;
  }
`;

const StyledLineIcons = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: var(--mainGreen);
  background-color: black;
  padding: 5px;
  border-radius: 3px;
`;

const LineSection = styled.div`
  width: 10%;
`;

const StyledLine = styled.hr`
  background-color: black;
  height: 170px;
  border: 0.5px solid black;
  width: 0.5px;

  position: relative;
  z-index: 0;
  @media (max-width: 425px) {
    height: 180px;
  }
`;

const FixedRates = styled.div`
  padding-top: 15px;
  width: 90%;
  @media (max-width: 425px) {
    width: 90%;
  }
`;

const Amount = styled.p`
  width: 30%;
  color: white;
  letter-spacing: 1px;
  :second-child {
    color: #d3d5d8;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

const AmountDesc = styled.p`
  color: #eaeaea;
  margin-left: 20px;
  :second-child {
    color: #d3d5d8;
  }
  :last-child() {
    color: #00b9ff;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

const CalculatorHeader = styled.div`
  margin-bottom: 20px;
  @media (max-width: 426px) {
    width: 100%;
  }
`;

const HeaderText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 65px;
  color: white;
  margin-top: 10px;
  @media (max-width: 425px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
