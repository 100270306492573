import React from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
import close from "../assets/close.svg";
import back from "../assets/back.svg";

function OutputModal(props) {
  return ReactDom.createPortal(
    <>
      {props.outputmodal && (
        <ModalContainer>
          <ModalBodyClose onClick={props.toggleOutputModal}></ModalBodyClose>
          <ModalDiv>
            <ModalClose onClick={props.toggleOutputModal}>
              <img src={close} alt="close tag" />
            </ModalClose>
            <ModalHeading>
              <img onClick={props.toggleOutputModal} src={back} alt="back" />
              {props.modalheading}
            </ModalHeading>
            {props.modalbody}
          </ModalDiv>
        </ModalContainer>
      )}
    </>,
    document.getElementById("portal")
  );
}

const ModalBodyClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(79, 86, 101, 0.2);
  backdrop-filter: blur(12px);
  @media (max-width: 768px) {
    display: none;
  }
`;

const ModalContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ModalDiv = styled.div`
  z-index: 2;
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 30%;
  left: 55%;
  transform: translateX(-50%);
  padding: 24px;
  @media (max-width: 991px) {
    max-width: 400px;
  }
  @media (max-width: 768px) {
    max-width: none;
    top: 0px;
    left: 0px;
    transform: translateX(0px);
    border-radius: 0px;
    min-height: 100vh;
  }
`;

const ModalClose = styled.button`
  display: grid;
  place-content: center;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  position: fixed;
  right: -50px;
  top: -50px;
  cursor:pointer;
  img {
    width: 15px;
    height: 15px;
  }
`;

const ModalHeading = styled.div`
  color: #0b132a;
  margin: 0px;
  font-size: 24px;
  padding: 0px 0px 16px;
  border-bottom: 1px solid rgba(53, 76, 109, 0.2);
  img {
    display: none;
  }

  @media (max-width: 768px) {
    display: flex;
    img {
      margin-right: 25px;
      display: flex;
    }
  }
`;

export default OutputModal;
