import React, { useState } from "react";
import styled from "styled-components";

import InfoImg from "../assets/info.svg";
import HideImg from "../assets/hide.svg";

import {media} from "../lib/helpers";
import {msg} from "../messages";

export default function Diagnosis({locale}) {
  const [show, setShow] = useState(true);
  const [wobble, setWobble] = useState(true);
  return (
    <Wrapper show={show}>
      <BlueWrapper onClick={() => setWobble(!wobble)}>
        <InfoIcon src={InfoImg} />
        <Title>{msg("diagnosis_message", locale)}</Title>
        <HideIcon
          src={HideImg}
          onClick={() => { setShow(false) }}
        />
      </BlueWrapper>
      <Display wobble={wobble}>
        <WhiteWrapper id="whitewrapper">
          <Heading>{msg("diagnosis_heading", locale)}</Heading>
          <TextWrapper>
            <Text>
              {msg("diagnosis_text", locale)}
            </Text>
          </TextWrapper>
          <ButtonWrapper>
            <a href="https://app.digitrad.world/quiz" target="_blank" rel="noreferrer">
              <Button>
                START
              </Button>
            </a>
          </ButtonWrapper>
        </WhiteWrapper>
      </Display>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  z-index: 101;
  width: 450px;
  height: auto;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 25px;
  animation: wrappermove 3s forwards;
  @keyframes wrappermove {
    from {bottom: -46px;}
    to {bottom: 0px;}
  }
  ${media.md`
    left: 0;
    right: 0;
    margin: 0 auto;
  `}
  ${media.sm`
    width: 90%;
  `}
`;

const BlueWrapper = styled.div`
  border-radius: 5px 5px 0 0;
  background-color: #30318C;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;

const InfoIcon = styled.img`
  margin: 0 10px;
  width: 22px;
  height: 22px;
`;

const Title = styled.div`
  font-size: 12px;
  color: #FFFFFF;
  ${media.sm`
    font-size: 8px;
  `}
`;

const HideIcon = styled.img`
  margin: 0 10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const Display = styled.div`
  display: ${props => props.wobble ? "none" : "block"};
`;

const WhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: center;
  background-color: #FFFFFF;
`;

const Heading = styled.div`
  margin-top: 43px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  ${media.sm`
    font-size: 15px;
  `}
`;

const TextWrapper = styled.div`
  width: 58%;
  margin: 0 auto 10px;
`;

const Text = styled.div`
  font-size: 9px;
`;

const ButtonWrapper = styled.div`
  background-color: #30318C;
  height: 40px;
  width: 56%;
  cursor: pointer !important;
  border-radius: 5px;
  margin: 0 auto 64px auto;
`;

const Button = styled.div`
  display: block;
  font-size: 16px;
  line-height: 40px;
  font-weight: bold;
  color: #FFFFFF;
`;